import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ContactPerson from "../../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../../components/hero";
import { CaseList } from "../../../components/caseList.en";

import doodle from "../../../assets/images/services/frontend.svg";

const FrontendUserExperience = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-frontend.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      fabio: file(relativePath: { eq: "profiles/fabio-baser.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="en" langPath="service.frontend.link">
      <Seo
        title="Frontend and user experience – Monday Consulting"
        description="We view frontend development work as a modern artisan craft. This is why we develop websites that are not only appealing but also functional, fast and failure-tolerant."
        keywords="Frontend, UX, user experience, headless, performance, TypeScript, accessibility, Lighthouse"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Every page a work of art"
        subline="Our Frontend Team develops high-quality, high-impact pages."
        section="Frontend & user experience"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Frontend & user experience"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img
              className="w-75"
              alt="Feature-packed perfection"
              src={doodle}
            />
          </div>
          <div className="col-md-6">
            <h2>Feature-packed perfection</h2>
            <p>
              If you’re aiming to impress a potential new customer,
              well-designed pages are only half the story: you also need to
              think about search engine optimization and performance. This is
              why we develop websites that are not only appealing but also
              functional, fast and failure-tolerant. We view frontend
              development work as a modern artisan craft: no two pages are quite
              alike but they all show the same attention to detail. The modern
              development toolbox offers our team plenty of advantages such as a
              shorter time-to-market, outstanding resilience and improved
              performance that positively influence your SEO. Yet we’re not
              afraid to make use of tried-and-tested tools where needed,
              deploying them wherever they add value to your project.
            </p>
            <p>
              Whether you’re breaking new ground or making improvements, we’re
              ready to take on any challenge and turn it into a success that you
              (and we) will be proud of. We keep the end user in the spotlight,
              and an excellent user experience, forward-thinking accessibility
              and optimum performance are all part of the service.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">
            Frontend & user experience in detail
          </h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-technology bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Modern tools and technologies
            </h3>
            <p>
              We make use of the most advanced languages and frameworks to help
              us accelerate project completion while maximising development
              quality. Many of our projects now use smart technologies like
              TypeScript, React and Tailwind CSS to boost your project
              time-to-market and keep it ahead of the game.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-headless bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Headless architecture
            </h3>
            <p>
              Separating out content and presentation makes content upkeep
              easier while giving designers greater freedom. Whatever your
              target look-and-feel, the sky’s the limit – thanks to the wealth
              of options and opportunities offered by these modern frameworks.
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-performance bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Performance = SEO
            </h3>
            <p>
              We develop high-performance websites that guarantee you a high
              Lighthouse score. This gives you the benefit of satisfied
              customers while boosting your search engine rankings.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-a11y bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Accessibility
            </h3>
            <p>
              We see coding for accessibility as a win-win scenario: not only
              required by law but also a self-evident sign of development
              quality. All of our websites are accessible, offering an
              outstanding user experience to each and every visitor.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Selected case studies</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="lebensbaum,generali" />
      </div>

      <ContactPerson
        headline="Interested?"
        text="Are you facing a similar challenge in your company? Talk to us today about your needs and requirements. Our experts look forward to helping you."
        contactEmail="frontend@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Fabio Baser, Unit Lead Frontend"
      >
        <GatsbyImage
          image={images.fabio.childImageSharp.gatsbyImageData}
          alt="Fabio Baser"
        />
      </ContactPerson>
    </Layout>
  );
};

export default FrontendUserExperience;
